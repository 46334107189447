import { useContext, useEffect, useMemo } from 'react';
import { ACTIONS } from '@utils/enums';
import { classNames } from '@utils/helpers';
import { appState } from '@utils/store';
import Typography from '@components/typography';
import { ActionContext } from '../../../context/action';
import { FreeBetContext } from '../../../context/free-bet';
import Suggestions from './parts/suggestions';
import styles from './index.module.css';

const BetInput = ({ betId, betValue, setBetValue }) => {
  const betState = useMemo(() => appState.bet[betId], [betId]);
  const { action } = useContext(ActionContext);
  const disabled = action !== ACTIONS.BET;

  const { suggestions } = useContext(FreeBetContext);

  const suggestion = useMemo(
    () => suggestions.find(({ betNominal }) => betNominal === betValue) || {},
    [betValue, suggestions]
  );

  useEffect(() => {
    if (betState.freeBetValue) return setBetValue(betState.freeBetValue);

    const { betNominal } = suggestions.find(({ total }) => total) || {};
    setBetValue(betNominal);
    betState.freeBetValue = betNominal;
  }, [betState, setBetValue, suggestions]);

  const setSuggestion = (value) => {
    betState.freeBetValue = value;
    setBetValue(value);
  };

  const containerClasses = classNames([styles.container, disabled && styles.disabled]);

  const { usage, total } = suggestion;

  return (
    <div className={containerClasses}>
      <div className={styles.inputContainer}>
        <Typography variant="bold" size="16">
          {betValue?.toFixed(2)}
        </Typography>

        <Typography variant="regular" size="14">
          Left {total - usage}/{total}
        </Typography>
      </div>

      <Suggestions onClick={setSuggestion} />
    </div>
  );
};

export default BetInput;
