import { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { appState } from '@utils/store';
import { FreeBetContext } from '../../context/free-bet';
import { onBet } from '../../helpers';
import FreeBet from '../free/check';
import AutoBet from './parts/auto/bet';
import AutoCashOut from './parts/auto/cashout';
import Button from './parts/button';
import styles from './index.module.css';

const BetButton = ({ betId, betValue }) => {
  const betState = useMemo(() => appState.bet[betId], [betId]);
  const { isFreeBet, showCheck } = useContext(FreeBetContext);

  const [autoBet, setAutoBet] = useState(betState.autoBet || false);
  const [autoCashOut, setAutoCashOut] = useState(betState.autoCashOut || null);

  useEffect(() => {
    betState.autoBet = autoBet;
  }, [betState, autoBet]);

  useEffect(() => {
    betState.autoCashOut = autoCashOut;
  }, [betState, autoCashOut]);

  const onBettingChange = useCallback(
    ({ action }) => onBet({ betId, action, betValue, maxMultiplier: autoCashOut, isFreeBet }),
    [betId, betValue, isFreeBet, autoCashOut]
  );

  return (
    <>
      <Button
        betId={betId}
        betValue={betValue}
        autoCashout={autoCashOut}
        autoBet={autoBet}
        setAutoBet={setAutoBet}
        onBettingChange={onBettingChange}
      />

      <div className={styles.auto}>
        <AutoBet checked={autoBet} setAutoBet={setAutoBet} />
        {showCheck ? <FreeBet /> : <div />}
        <AutoCashOut
          betId={betId}
          betValue={betValue}
          checked={!!autoCashOut}
          initValue={autoCashOut}
          setAutoCashOut={setAutoCashOut}
        />
      </div>
    </>
  );
};

export default BetButton;
